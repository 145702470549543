<template>
  <p class="notecard" ref="notecardRef" tabindex="-1">
    <slot></slot>
  </p>
</template>

<script setup lang="ts">
import { MessageType, MessageTypes } from "~/utils/constants";

type Props = {
  focused?: boolean;
  isFieldValidation?: boolean;
  messageType?: MessageTypes;
  withBorder?: boolean;
};

type ThemeProps = {
  background: string;
  border: string | number;
  topMargin: number | string;
  padding: string;
  text: string;
};

const props = withDefaults(defineProps<Props>(), {
  focused: false,
  isFieldValidation: false,
  messageType: MessageType.INFO,
  withBorder: false,
});

const notecardRef = ref<HTMLElement | null>(null);

onMounted(() => {
  if (props.focused) {
    notecardRef.value?.focus();
  }
});

const theme: ThemeProps = {
  background: `var(--notecard-${props.messageType}-background)`,
  border: 0,
  topMargin: 0,
  padding: "var(--layout-base-spacing)",
  text: `var(--notecard-${props.messageType}-text)`,
};

if (props.withBorder || props.focused) {
  theme.border = `var(--notecard-${props.messageType}-border)`;
}

if (props.isFieldValidation) {
  theme.padding = "var(--layout-narrow-spacing) var(--layout-base-spacing)";
  theme.topMargin = "var(--layout-narrow-spacing)";
}
</script>

<style scoped>
.notecard {
  background-color: v-bind("theme.background");
  border-bottom: v-bind("theme.border");
  border-top: v-bind("theme.border");
  color: v-bind("theme.text");
  margin-top: v-bind("theme.topMargin");
  padding: v-bind("theme.padding");
}

.notecard:focus {
  border-bottom: v-bind("theme.border");
  border-top: v-bind("theme.border");
}
</style>
